/* eslint-disable */
export class SchoettlerCore {

  activeIndex: number;
  counter: number;
  options: { [k: string]: any };
  tocContents: Array<any>

  constructor() {

    this.activeIndex = 0;
    this.tocContents = [];
    this.counter = 0;
    this.options = {
      allowedClickEventTargets: ['isOffCanvasSwitch', 'qtyControl', 'linkAction', 'loadWhileNavigating'],
      rootMargin: '-100px'
    };

    this.loadLibraries();
    this.generateTableOfContents();
  }

  generateTableOfContents(): void {

    const headings = this.$$('[data-toc-index]');
    const tocEl = this.$('[data-table-of-contents]');
    const heading = this.$('[data-table-of-contents] span');
    const tocItemsEl = document.createElement('div');
    tocItemsEl.setAttribute('class', 'items-wrapper')

    // Remove TOC container
    if(!tocEl) {
      return;
    }

    if(!!!headings || headings.length < 2) {

      if(tocEl) {
        this.removeElement(tocEl);
      }
      return
    }

    // Insert Image Wrapper and Image right before heading
    const trigger = document.createElement('label');
    trigger.setAttribute('class', 'trigger');
    trigger.setAttribute('for', 'tco-trigger');

    trigger.innerHTML = `<img src="" data-src="assets/img/icons/frontend/mobile/icon-toc.svg" alt="Inhaltsverzeichnis öffnen oder schließen" class="img--resp lazyload">`;
    tocEl.insertBefore(trigger, heading);

    headings.forEach((el, index) => {

      const node = document.createElement('a');

      node.setAttribute('data-toc-index', '');
      node.setAttribute('href', `#${el.id}`);
      node.setAttribute('data-on-page-target', ``);
      node.setAttribute('class', index === 0 ? 'current' : '');

      node.textContent = el.dataset.headingContent;
      tocItemsEl.append(node);

      // Save nodes as references for easier class manipulation
      this.tocContents = [
        ...this.tocContents,
        node
      ];

      // console.log(this.tocContents);
    })

    tocEl.append(tocItemsEl);

    this.removeElement('.spinner', tocEl);

    const options = {
      // @ts-ignore
      root: null, // relative to document viewport
      rootMargin: this.options.rootMargin,
      threshold: 1.0
    };

    const _this = this;
    const callback = this.handleActiveHeading.bind(this);

    // @ts-ignore
    const observer = new IntersectionObserver(callback/*.bind(this, this.tocContents)*/, options);

    headings.forEach((heading) => {
      observer.observe(heading)
    });
  };

  handleActiveHeading(elements: Array<IntersectionObserverEntry>/*, core: any*/): any {

    // current index must be memoized or tracked outside of function for comparison
    let localActiveIndex = this.activeIndex

    // track which elements register above or below the document's current position
    let aboveIndices: Array<any> = [];
    let belowIndices: Array<any> = [];

    // loop through each intersection element
    //  due to the asynchronous nature of observers, callbacks must be designed to handle 1 or many intersecting elements
    elements.forEach((element: any) => {

      // detect if intersecting element is above the browser viewport; include cross browser logic
      const boundingClientRectY = (typeof element.boundingClientRect.y !== 'undefined') ? element.boundingClientRect.y : element.boundingClientRect.top
      const rootBoundsY = (typeof element.rootBounds.y !== 'undefined') ? element.rootBounds.y : element.rootBounds.top
      const isAbove = boundingClientRectY < rootBoundsY;

      // get index of intersecting element from DOM attribute
      const intersectingElemIdx = parseInt(element.target.getAttribute('data-toc-index'));

      // record index as either above or below current index
      if (isAbove) {
        aboveIndices = [...aboveIndices, intersectingElemIdx];
      } else {
        belowIndices = [...belowIndices, intersectingElemIdx];
      }
    })

    // determine min and max fired indices values (support for multiple elements firing at once)
    const minIndex = Math.min(...belowIndices);
    const maxIndex = Math.max(...aboveIndices);

    // determine how to adjust localActiveIndex based on scroll direction
    if (aboveIndices.length > 0) {
      // scrolling down - set to max of fired indices
      localActiveIndex = maxIndex;
    } else if (belowIndices.length > 0 && minIndex <= this.activeIndex) {
      // scrolling up - set to minimum of fired indices
      localActiveIndex = (minIndex - 1 >= 0) ? minIndex - 1 : 0
    }

    if (localActiveIndex !== this.activeIndex) {
      this.tocContents[this.activeIndex].classList.remove('current')
      this.tocContents[localActiveIndex].classList.add('current')
      this.activeIndex = localActiveIndex
    }
  }

  /**
   * Removes one or multiple element from the DOM. Similar to jQuery's remove() function.
   *
   * @param el The element to be removed {string|NodeList}
   * @param parent
   */
  removeElement(el: string | NodeList | any, parent?: HTMLElement): void {

    // Check if parameter might be a NodeList, else it is an element selector
    if (el.constructor === NodeList) {

      el.forEach(function (element) {

        const _node = element;
        _node.parentNode.removeChild(_node);
      })
    } else if (el instanceof HTMLElement){
      // alert('implement: el instanceof HTMLElement');
      el.parentNode.removeChild(el);
    } else {

      const _node = (parent ? parent : document).querySelector(el);

      if(_node) {
        _node.parentNode.removeChild(_node);
      }
    }
  };

  loadLibraries(): void {

    // Lazysizes
    this.addScript(`assets/js/vendor/lazysizes/ls.unveilhooks.js`,
      () => { this.addScript(`assets/js/vendor/lazysizes/lazysizes.min.js`, null, 'lazy-sizes-core'); },
      'lazy-sizes-unveil-hooks'
    );

    // Zenscroll
    this.addScript(
  'assets/js/vendor/zenscroll/zenscroll.min.js', () => {
        // @ts-ignore
        if (window.zenscroll) {
          // @ts-ignore
          window.zenscroll.setup(1000, 120)
        }
    },
      'zenscroll'
    );
  }

  addScript(url: string, callback: any, name: string): void {

    if (!!this.$('script[src="' + url + '"]')) {
      setTimeout(() => callback ? callback.call() : () => console.log(`${name} has been loaded`), 500);
    } else {

      const script = document.createElement('script');
      script.type = 'text/javascript';
      if (name) {
        script.title = name;
      }

      document.body.appendChild(script);
      script.src = url;

      if (callback) {
        script.onload = callback;
      }
    }
  };

  $(selector: string, parent: HTMLElement = null): HTMLElement {
    return (parent ? parent : document).querySelector(selector);
  };

  $$(selector: string, parent: HTMLElement = null): Array<HTMLElement> {
    return Array.from((parent ? parent : document).querySelectorAll(selector));
  };

  /**
   * Opens or closes the off-canvas menu, respectively
   */
  toggleOffCanvasMenu() {
    const menu = this.$('.offcvnav__outer');
    menu.dataset.visible = menu.dataset.visible === 'true' ? 'false' : 'true';
  };
}
